import { IAPIModel } from "@pasabi/ui-patterns";
import { RunsStore } from "../store/modules/Runs";
import { SubjectsListStore } from "../store/modules/SubjectsList";
import { IPsListStore } from "../store/modules/IPsList";
import { ISPsListStore } from "../store/modules/ISPsList";
import { ActorsListStore } from "../store/modules/ActorsList";
import { EventsListStore } from "../store/modules/EventsList";
import { EntityTypes } from "../types/ui";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isObject = (item: any) => {
  return item && typeof item === "object" && !Array.isArray(item);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mergeDeep = (target: Record<string, any>, source: Record<string, any>) => {
  const output = { ...target };

  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key: string) => {
      if (isObject(source[key])) {
        if (!(key in target)) Object.assign(output, { [key]: source[key] });
        else output[key] = mergeDeep(target[key], source[key]);
      } else {
        Object.assign(output, { [key]: source[key] });
      }
    });
  }
  return output;
};

/**
 * It gets the API model.
 * if the value is not yet available, it will trigger the association fetch
 */
export const useEntityModel = (entityType: EntityTypes): IAPIModel | undefined => {
  const store = {
    [EntityTypes.actors]: ActorsListStore,
    [EntityTypes.subjects]: SubjectsListStore,
    [EntityTypes.events]: EventsListStore,
    [EntityTypes.ips]: IPsListStore,
    [EntityTypes.isps]: ISPsListStore,
  }[entityType];

  const key = "model";
  const data = store.associationData({ key });

  /**
   * check if the association was already loaded
   */
  if (
    !data &&
    !store.isLoadingAssociation({ key }) &&
    !store.isAsssociationLoaded({ key }) &&
    !store.associationLoadingError({ key }) &&
    RunsStore.selectedRun
  ) {
    store.fetchAssociation({ key });

    return undefined;
  }
  return data;
};

export const useEntityModelFields = (entityType: EntityTypes): string[] => {
  return useEntityModel(entityType)?.fields.map(({ name }) => name) || [];
};
