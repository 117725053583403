const SignIn = () => import("../views/auth/SignIn.vue");
const SignOut = () => import("../views/auth/SignOut.vue");
const SignInRedirect = () => import("../views/auth/SignInRedirect.vue");
const ForgotPassword = () => import("../views/auth/ForgotPassword.vue");
const ResetPassword = () => import("../views/auth/ResetPassword.vue");
const AcceptInvite = () => import("../views/auth/AcceptInvite.vue");
const ActivateAccount = () => import("../views/auth/ActivateAccount.vue");

export default [
  {
    path: "/",
    name: "SignInRedirect",
    component: SignInRedirect,
    meta: {
      auth: false,
    },
  },
  {
    path: "sign-in",
    name: "SignIn",
    component: SignIn,
    meta: {
      auth: false,
    },
  },
  {
    path: "signout",
    name: "SignOut",
    component: SignOut,
    meta: {
      auth: false,
    },
  },
  {
    path: "reminder",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: {
      auth: false,
    },
  },
  {
    path: "reset",
    name: "ResetPassword",
    component: ResetPassword,
    meta: {
      auth: false,
    },
  },
  {
    path: "acceptInvite",
    name: "AcceptInvite",
    component: AcceptInvite,
    meta: {
      auth: false,
    },
  },
  {
    path: "activate",
    name: "ActivateAccount",
    component: ActivateAccount,
    meta: {
      auth: false,
    },
  },
];
