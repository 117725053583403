import SubjectGraphNode from "./graph/SubjectGraphNode.vue";
import ProfileGraphNode from "./graph/ActorGraphNode.vue";
import ReviewGraphNode from "./graph/EventGraphNode.vue";
import IPGraphNode from "./graph/IPGraphNode.vue";
import Vue from "vue";

Vue.component("subject-graph-node", SubjectGraphNode);
Vue.component("actor-graph-node", ProfileGraphNode);
Vue.component("event-graph-node", ReviewGraphNode);
Vue.component("ip-graph-node", IPGraphNode);
