import { Module, getModule } from "vuex-module-decorators";
import { IListStoreAssociations, ListingPageStore } from "@pasabi/ui-patterns";
import store from "..";
import { $api } from "../../api/api";
import { ISP } from "../../types";
import { RunsStore } from "./Runs";

@Module({
  name: "ISPsList",
  store,
  dynamic: true,
  namespaced: true,
})
class ISPsList extends ListingPageStore<ISP> {
  get itemsApiPath() {
    return `/isps/${RunsStore.selectedRunId}`;
  }

  _associations: IListStoreAssociations = {
    filters: {
      _apiPath: () => `/isps/${RunsStore.selectedRunId}/filters`,
    },
    model: {
      _apiPath: () => `/isps/${RunsStore.selectedRunId}/model`,
    },
  };

  get api() {
    return $api;
  }
}

export const ISPsListStore = getModule(ISPsList);
