import { RawLocation } from "vue-router";

export enum MainSections {
  dashboard = "dashboard",
  subjects = "subjects",
  actors = "actors",
  ips = "ips",
  isps = "isps",
  events = "events",
}

export enum EntityTypes {
  subjects = "subjects",
  actors = "actors",
  ips = "ips",
  isps = "isps",
  events = "events",
}

export enum EventTabs {
  overview = "overview",
  relatedEvents = "relatedEvents",
  exactEvents = "exactEvents",
  closeEvents = "closeEvents",
  notes = "notes",
}

export enum ActorTabs {
  overview = "overview",
  events = "events",
  actors = "actors",
  subjects = "subjects",
  ips = "ips",
  isps = "isps",
  notes = "notes",
}

export enum SubjectTabs {
  overview = "overview",
  events = "events",
  actors = "actors",
  subjects = "subjects",
  ips = "ips",
  isps = "isps",
  notes = "notes",
}

export enum IPsTabs {
  overview = "overview",
  events = "events",
  actors = "actors",
  subjects = "subjects",
}

export enum ISPsTabs {
  overview = "overview",
  ips = "ips",
  subjects = "subjects",
  actors = "actors",
  events = "events",
}

export enum ActorOverviewSections {
  overview = "overview",
  dailyEventsBreakdown = "dailyEventsBreakdown",
  eventsBreakdown = "eventsBreakdown",
  events = "events",
  ips = "ips",
  isps = "isps",
  scoresHistory = "scoresHistory",
  subjects = "subjects",
  actors = "actors",
  phoneNumbers = "phoneNumbers",
}

export enum SubjectOverviewSections {
  overview = "overview",
  scoresHistory = "scoresHistory",
  dailyEventsBreakdown = "dailyEventsBreakdown",
  eventsBreakdown = "eventsBreakdown",
  events = "events",
  ips = "ips",
  isps = "isps",
  subjects = "subjects",
  actors = "actors",
}

export enum IPOverviewSections {
  overview = "overview",
  details = "details",
  dailyEventsBreakdown = "dailyEventsBreakdown",
}

export enum ISPOverviewSections {
  overview = "overview",
  countriesBreakdown = "countriesBreakdown",
}

export enum EventOverviewSections {
  content = "content",
  details = "details",
}

export type EntityTabs = {
  [EntityTypes.events]: EventTabs;
  [EntityTypes.actors]: ActorTabs;
  [EntityTypes.subjects]: SubjectTabs;
  [EntityTypes.ips]: IPsTabs;
  [EntityTypes.isps]: ISPsTabs;
};

export interface SidebarLinks {
  key: MainSections;
  label: string;
  icon: string;
  to: string | RawLocation;
  tooltip?: string;
}
