import { RouteConfig } from "vue-router";
const RouterViewPassthrough = () => import("../views/RouterViewPassthrough.vue");
const SubjectOverview = () => import("../views/subjects/SubjectOverviewTab.vue");
const SubjectDetails = () => import("../views/subjects/SubjectDetails.vue");
const SubjectsListing = () => import("../views/subjects/SubjectsListing.vue");
const SubjectEvents = () => import("../views/subjects/SubjectEventsTab.vue");
const SubjectIPs = () => import("../views/subjects/SubjectIPsTab.vue");
const SubjectISPs = () => import("../views/subjects/SubjectISPsTab.vue");
const SubjectActors = () => import("../views/subjects/SubjectActorsTab.vue");
const SubjectRelatedSubjects = () => import("../views/subjects/SubjectRelatedSubjectsTab.vue");
const SubjectNotes = () => import("../views/subjects/SubjectNotesTab.vue");
const SubjectGraph = () => import("../views/subjects/SubjectGraph.vue");

const routes: Array<RouteConfig> = [
  {
    path: "subjects/",
    component: RouterViewPassthrough,
    meta: {
      breadcrumb: "Businesses",
      breadcrumbOverrideName: "SubjectsListing",
    },
    children: [
      {
        path: "",
        name: "SubjectsListing",
        component: SubjectsListing,
        meta: {
          breadcrumb: "Dashboard",
        },
      },
      {
        path: ":id",
        component: SubjectDetails,
        name: "subject>details",
        meta: {
          breadcrumb: function breadcrumb() {
            return this.$store.getters["SubjectDetails/name"](this.$route.params.id);
          },
          breadcrumbOverrideName: "subject>overview",
        },
        redirect: { name: "subject>overview" },
        children: [
          {
            path: "",
            name: "subject>overview",
            component: SubjectOverview,
            meta: {
              breadcrumb: "Overview",
            },
          },
          {
            path: "events",
            name: "subject>events",
            component: SubjectEvents,
            meta: {
              breadcrumb: "Reviews",
            },
          },
          {
            path: "actors",
            name: "subject>actors",
            component: SubjectActors,
            meta: {
              breadcrumb: "Profiles",
            },
          },
          {
            path: "subjects",
            name: "subject>relatedSubjects",
            component: SubjectRelatedSubjects,
            meta: {
              breadcrumb: "Related Businesses",
            },
          },
          {
            path: "ips",
            name: "subject>ips",
            component: SubjectIPs,
            meta: {
              breadcrumb: "IPs",
            },
          },
          {
            path: "isps",
            name: "subject>isps",
            component: SubjectISPs,
            meta: {
              breadcrumb: "ISPs",
            },
          },
          {
            path: "notes",
            name: "subject>notes",
            component: SubjectNotes,
            meta: {
              breadcrumb: "Notes",
            },
          },
          {
            path: "graph",
            name: "subject>graph",
            component: SubjectGraph,
            meta: {
              breadcrumb: "Graph",
              plainLayout: true,
            },
          },
        ],
      },
    ],
  },
];

export default routes;
