import { RouteConfig } from "vue-router";
const RouterViewPassthrough = () => import("../views/RouterViewPassthrough.vue");
const ISPsListing = () => import("../views/isps/ISPsListing.vue");
const ISPDetails = () => import("../views/isps/ISPDetails.vue");
const ISPOverviewTab = () => import("../views/isps/ISPOverviewTab.vue");
const ISPIPsTab = () => import("../views/isps/ISPIPsTab.vue");
const ISPEventsTab = () => import("../views/isps/ISPEventsTab.vue");
const ISPActorsTab = () => import("../views/isps/ISPActorsTab.vue");
const ISPSubjectsTab = () => import("../views/isps/ISPSubjectsTab.vue");

const routes: Array<RouteConfig> = [
  {
    path: "isps/",
    component: RouterViewPassthrough,
    meta: {
      breadcrumb: "ISPs",
      breadcrumbOverrideName: "ISPsListing",
    },
    children: [
      {
        path: "",
        name: "ISPsListing",
        component: ISPsListing,
        meta: {
          breadcrumb: "ISPs",
        },
      },
      {
        path: ":id",
        component: ISPDetails,
        name: "isp>details",
        meta: {
          breadcrumb: function breadcrumb() {
            return this.$store.getters["ISPDetails/name"](this.$route.params.id);
          },
          breadcrumbOverrideName: "isp>overview",
        },
        redirect: { name: "isp>overview" },
        children: [
          {
            path: "",
            component: RouterViewPassthrough,
            children: [
              {
                path: "",
                name: "isp>overview",
                component: ISPOverviewTab,
                meta: {
                  breadcrumb: "Overview",
                },
              },
              {
                path: "events",
                name: "isp>events",
                component: ISPEventsTab,
                meta: {
                  breadcrumb: "Reviews",
                },
              },
              {
                path: "subjects",
                name: "isp>subjects",
                component: ISPSubjectsTab,
                meta: {
                  breadcrumb: "Businesses",
                },
              },
              {
                path: "actors",
                name: "isp>actors",
                component: ISPActorsTab,
                meta: {
                  breadcrumb: "Profiles",
                },
              },
              {
                path: "ips",
                name: "isp>ips",
                component: ISPIPsTab,
                meta: {
                  breadcrumb: "IPs",
                },
              },
            ],
          },
        ],
      },
    ],
  },
];

export default routes;
