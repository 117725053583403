import { render, staticRenderFns } from "./IPGraphNode.vue?vue&type=template&id=ce06051a"
import script from "./IPGraphNode.vue?vue&type=script&lang=ts"
export * from "./IPGraphNode.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@2.7.15_css-loader@6.8.1_webpack@5.89.0__ejs@3.1.10_handl_zc54ymokgdd4k6irihoph6aqli/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports