import { Module, getModule } from "vuex-module-decorators";
import { DetailsPageStore, IDetailsStoreAssociations } from "@pasabi/ui-patterns";
import store from "..";
import { $api } from "../../api/api";
import { Actor } from "../../types";
import { RunsStore } from "./Runs";

@Module({
  name: "ActorDetails",
  store,
  dynamic: true,
  namespaced: true,
})
class ActorDetails extends DetailsPageStore<Actor> {
  _associations: IDetailsStoreAssociations = {
    overview: {
      _apiPath: ({ id }) => {
        return `/actors/${id}/${RunsStore.selectedRunId}/overview`;
      },
    },
    graph: {
      _apiPath: ({ id }) => {
        return `/actors/${id}/${RunsStore.selectedRunId}/graph`;
      },
    },
    events: {
      _apiPath: ({ id }) => `/actors/${id}/${RunsStore.selectedRunId}/events`,
    },
    eventsFilters: {
      _apiPath: ({ id }) => `/actors/${id}/${RunsStore.selectedRunId}/events/filters`,
    },
    subjects: {
      _apiPath: ({ id }) => `/actors/${id}/${RunsStore.selectedRunId}/subjects`,
    },
    subjectsFilters: {
      _apiPath: ({ id }) => `/actors/${id}/${RunsStore.selectedRunId}/subjects/filters`,
    },
    actors: {
      _apiPath: ({ id }) => `/actors/${id}/${RunsStore.selectedRunId}/actors`,
    },
    actorsFilters: {
      _apiPath: ({ id }) => `/actors/${id}/${RunsStore.selectedRunId}/actors/filters`,
    },
    ips: {
      _apiPath: ({ id }) => `/actors/${id}/${RunsStore.selectedRunId}/ips`,
    },
    ipsFilters: {
      _apiPath: ({ id }) => `/actors/${id}/${RunsStore.selectedRunId}/ips/filters`,
    },
    isps: {
      _apiPath: ({ id }) => `/actors/${id}/${RunsStore.selectedRunId}/isps`,
    },
    ispsFilters: {
      _apiPath: ({ id }) => `/actors/${id}/${RunsStore.selectedRunId}/isps/filters`,
    },
  };

  get itemApiPath(): (id?: string) => string {
    return (id) => {
      return `/actors/${id}/${RunsStore.selectedRunId}/details`;
    };
  }

  get api() {
    return $api;
  }

  get actorEmail() {
    return (itemId: string) => {
      const actor = this.item(itemId);

      if (actor) {
        return actor.email_address;
      }
      return "";
    };
  }
}

export const ActorDetailsStore = getModule(ActorDetails);
