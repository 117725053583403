<template>
  <div>
    <div v-if="isLoading"><ui-preloader /></div>
    <div v-else-if="isLoaded && subject">
      <div class="row row--flow-vertical">
        <div class="col col--flow-vertical ui-node-overview__heading">
          <dl>
            <dt class="label">
              {{ $t("graph.subject.fields.name") }}
            </dt>
            <dd class="txt--large txt--bold">
              {{ subject.name }}
            </dd>
          </dl>
        </div>
        <div class="row row--wrap">
          <div :class="`col col--${p.size || 50}`" v-for="p in properties" v-bind:key="p.label">
            <dl>
              <dt>{{ p.label }}</dt>
              <dd>{{ p.value }}</dd>
            </dl>
          </div>
        </div>
        <div class="col" v-if="type == 'overview'">
          <ui-button
            appearance="primary"
            span="100"
            component="router-link"
            target="_blank"
            :to="subjectLocation"
          >
            {{ $t("graph.subject.view-details") }}
          </ui-button>
        </div>
      </div>
    </div>
    <div v-else-if="loadingError">
      <ui-node-overview :node="node" :useCustomComponent="false" />
    </div>
  </div>
</template>

<script lang="ts">
import { IGraphNode } from "@pasabi/ui-blossom/types/interfaces/graph";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { UiButton, UiPreloader } from "@pasabi/ui-components";
import { RawLocation } from "vue-router";
import { SubjectDetailsStore } from "../../store/modules/SubjectDetails";
import { SubjectGraph } from "../../types";
import { UiNodeOverview } from "@pasabi/ui-blossom";

@Component({
  components: {
    UiButton,
    UiPreloader,
    UiNodeOverview,
  },
})
export default class SubjectGraphNode extends Vue {
  store = SubjectDetailsStore;

  @Prop({ required: true }) node!: IGraphNode;
  @Prop({ required: true }) type!: "overlay" | "overview";

  get subjectId() {
    return this.node.properties.display_id;
  }

  @Watch("suspectId", { immediate: true })
  suspectIdChanged() {
    if (this.subjectId) {
      this.loadData();
    }
  }

  loadData() {
    this.store.fetchItemAssociation({ id: this.subjectId, key: "graph" });
  }

  get properties(): { label: string; value: string | number; size?: number }[] {
    if (this.subject) {
      return [
        {
          label: this.$t("graph.subject.fields.id").toString(),
          value: this.subject.external_id,
          size: 100,
        },
        { label: this.$t("graph.subject.fields.tier").toString(), value: this.subject.tier },
        {
          label: this.$t("graph.subject.fields.actorScore").toString(),
          value: `${Math.round(this.subject.actors_over_threshold_percentage * 100)}%`,
        },
        {
          label: this.$t("graph.subject.fields.eventScore").toString(),
          value: `${Math.round(this.subject.events_over_threshold_percentage * 100)}%`,
        },
        {
          label: this.$t("graph.subject.fields.action").toString(),
          value: this.$t(`filters.shouldAction.${this.subject.action}`).toString(),
        },
        {
          label: this.$t("graph.subject.fields.eventsCount").toString(),
          value: this.subject.events_over_threshold,
        },
        {
          label: this.$t("graph.subject.fields.actorsCount").toString(),
          value: this.subject.actors_over_threshold,
        },
        {
          label: this.$t("graph.subject.fields.ipsCount").toString(),
          value: this.subject.ip_count,
        },
      ];
    }
    return [];
  }

  get isLoading() {
    return this.store.isLoadingAssociation({ id: this.subjectId, key: "graph" });
  }

  get isLoaded() {
    return this.store.isAsssociationLoaded({ id: this.subjectId, key: "graph" });
  }

  get loadingError() {
    return this.store.associationLoadingError({ id: this.subjectId, key: "graph" });
  }

  get subject(): SubjectGraph {
    return this.store.associationData({ id: this.subjectId, key: "graph" });
  }

  get subjectLocation(): RawLocation {
    return {
      name: "subject>details",
      params: {
        ...this.$router.currentRoute.params,
        id: this.subjectId,
      },
    };
  }
}
</script>
