import { RouteConfig } from "vue-router";
const RouterViewPassthrough = () => import("../views/RouterViewPassthrough.vue");
const IpsListing = () => import("../views/ips/IPsListing.vue");
const IpDetails = () => import("../views/ips/IPDetails.vue");
const IpOverviewTab = () => import("../views/ips/IPOverviewTab.vue");
const IpEventsTab = () => import("../views/ips/IPEventsTab.vue");
const IpActorsTab = () => import("../views/ips/IPActorsTab.vue");
const IpSubjectsTab = () => import("../views/ips/IPSubjectsTab.vue");

const routes: Array<RouteConfig> = [
  {
    path: "ips/",
    component: RouterViewPassthrough,
    meta: {
      breadcrumb: "IPs",
      breadcrumbOverrideName: "IPsListing",
    },
    children: [
      {
        path: "",
        name: "IPsListing",
        component: IpsListing,
        meta: {
          breadcrumb: "IPs",
        },
      },
      {
        path: ":id",
        component: IpDetails,
        name: "ip>details",
        meta: {
          breadcrumb: function breadcrumb() {
            return this.$store.getters["IPDetails/address"](this.$route.params.id);
          },
          breadcrumbOverrideName: "ip>overview",
        },
        redirect: { name: "ip>overview" },
        children: [
          {
            path: "",
            component: RouterViewPassthrough,
            children: [
              {
                path: "",
                name: "ip>overview",
                component: IpOverviewTab,
                meta: {
                  breadcrumb: "Overview",
                },
              },
              {
                path: "events",
                name: "ip>events",
                component: IpEventsTab,
                meta: {
                  breadcrumb: "Reviews",
                },
              },
              {
                path: "subjects",
                name: "ip>subjects",
                component: IpSubjectsTab,
                meta: {
                  breadcrumb: "Businesses",
                },
              },
              {
                path: "actors",
                name: "ip>actors",
                component: IpActorsTab,
                meta: {
                  breadcrumb: "Profiles",
                },
              },
            ],
          },
        ],
      },
    ],
  },
];

export default routes;
