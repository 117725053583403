import { Module, getModule } from "vuex-module-decorators";
import { DetailsPageStore, IDetailsStoreAssociations } from "@pasabi/ui-patterns";
import store from "..";
import { $api } from "../../api/api";
import { Event } from "../../types";
import { RunsStore } from "./Runs";

@Module({
  name: "EventDetails",
  store,
  dynamic: true,
  namespaced: true,
})
class EventDetails extends DetailsPageStore<Event> {
  _associations: IDetailsStoreAssociations = {
    overview: {
      _apiPath: ({ id }) => {
        return `/events/${id}/${RunsStore.selectedRunId}/overview`;
      },
    },
    relatedEvents: {
      _apiPath: ({ id }) => `/events/${id}/${RunsStore.selectedRunId}/related_events`,
    },
    relatedEventsFilters: {
      _apiPath: ({ id }) => `/events/${id}/${RunsStore.selectedRunId}/related_events/filters`,
    },
    exactEvents: {
      _apiPath: ({ id }) => `/events/${id}/${RunsStore.selectedRunId}/exact_events`,
    },
    exactEventsFilters: {
      _apiPath: ({ id }) => `/events/${id}/${RunsStore.selectedRunId}/exact_events/filters`,
    },
    closeEvents: {
      _apiPath: ({ id }) => `/events/${id}/${RunsStore.selectedRunId}/close_events`,
    },
    closeEventsFilters: {
      _apiPath: ({ id }) => `/events/${id}/${RunsStore.selectedRunId}/close_events/filters`,
    },
  };

  get itemApiPath(): (id?: string) => string {
    return (id) => {
      return `/events/${id}/${RunsStore.selectedRunId}/details`;
    };
  }

  get api() {
    return $api;
  }

  get externalId() {
    return (itemId: string) => {
      const event = this.item(itemId);

      return event?.external_id || "";
    };
  }
}

export const EventDetailsStore = getModule(EventDetails);
