import Vue from "vue";
import VueI18n, { LocaleMessages } from "vue-i18n";
import countries from "i18n-iso-countries";
import { mergeDeep } from "./utils/utils";

Vue.use(VueI18n);

// eslint-disable-next-line @typescript-eslint/no-var-requires
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

function loadLocaleMessages(): LocaleMessages {
  const reviewsLocales = require.context("./locales", true, /[A-Za-z0-9-_,\s]+\.json$/i);

  const patternsLocales = require.context(
    "@pasabi/ui-patterns/dist/locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i,
  );

  const blossomLocales = require.context(
    "@pasabi/ui-blossom/dist/locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i,
  );

  const chartsLocales = require.context(
    "@pasabi/ui-charts/dist/locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i,
  );

  const messages: LocaleMessages = {};

  [patternsLocales, blossomLocales, chartsLocales, reviewsLocales].forEach((locale) => {
    locale.keys().forEach((key) => {
      const matched = key.match(/([A-Za-z0-9-_]+)\./i);
      if (matched && matched.length > 1) {
        const match = matched[1];
        messages[match] = mergeDeep(messages[match] || {}, locale(key));
      }
    });
  });

  return messages;
}

const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: loadLocaleMessages(),
});

export default i18n;

export const getCountryNameFromCode = (code: string): string => {
  return countries.getName(code, i18n.locale);
};

/**
 * Dynamic Keys
 * i18n.t("filters.action.label")
 * i18n.t("filters.business_name.label")
 * i18n.t("filters.tier.label")
 * i18n.t("filters.shouldAction.label")
 * i18n.t("filters.shouldAction.action")
 * i18n.t("filters.shouldAction.investigate")
 * i18n.t("filters.shouldAction.no-action")
 * i18n.t("filters.totalReviews.label")
 * i18n.t("filters.reviewsOverThreshold.label")
 * i18n.t("filters.profileCount.label")
 * i18n.t("filters.actorsOverThreshold.label")
 * i18n.t("filters.eventsPercentage.label")
 * i18n.t("filters.actorsPercentage.label")
 * i18n.t("filters.ip.label")
 * i18n.t("filters.isp.label")
 * i18n.t("filters.events.label")
 * i18n.t("filters.connection_type.label")
 * i18n.t("filters.fraud_score.label")
 * i18n.t("filters.ip_city.label")
 * i18n.t("filters.ip_country.label")
 * i18n.t("filters.eventsCount.label")
 * i18n.t("filters.profilesCount.label")
 * i18n.t("filters.ipsCount.label")
 * i18n.t("filters.subjectsCount.label")
 * i18n.t("filters.actorName.label")
 * i18n.t("filters.actorExternalId.label")
 * i18n.t("filters.email.label")
 * i18n.t("filters.shouldAction.label")
 * i18n.t("filters.shouldAction.true")
 * i18n.t("filters.shouldAction.false")
 * i18n.t("filters.containsSpam.label")
 * i18n.t("filters.similarEmails.label")
 * i18n.t("filters.geocScore.label")
 * i18n.t("filters.countGeoc.label")
 * i18n.t("filters.review_id.label")
 * i18n.t("filters.status.label")
 * i18n.t("filters.totalReviewScore.label")
 * i18n.t("filters.totalReviewScorePercentage.label")
 * i18n.t("filters.isReviewSpam.label")
 * i18n.t("filters.profanity.label")
 * i18n.t("filters.closeMatchesCount.label")
 * i18n.t("filters.exactMatchesCount.label")
 * i18n.t("filters.mobile_number.label")
 * i18n.t("filters.telephone_number_valid.label")
 * i18n.t("filters.mobile_number_valid.label")
 * i18n.t("filters.mobile_number_active.label")
 * i18n.t("filters.telephone_number_active.label")
 * i18n.t("filters.email_match.label")
 * i18n.t("filters.phone_match.label")
 * i18n.t("filters.totalPostCount.label")
 *
 * i18n.t("filters.status.label")
 * i18n.t("filters.status.ActiveReview")
 * i18n.t("filters.status.FilteredReview")
 * i18n.t("filters.status.PendingReview")
 * i18n.t("filters.labels.label")
 * i18n.t("filters.actorsEventLabels.label")
 *
 * i18n.t("search.placeholder.profiles")
 * i18n.t("search.placeholder.businesses")
 *
 * i18n.t("queries.positiveBiasCrossIPCombo")
 * i18n.t("queries.secondaryQuery")
 * i18n.t("queries.positiveBiasLocalIPCAT")
 * i18n.t("queries.catFlaggedData")
 * i18n.t("queries.behaviourAnalysis")
 */
