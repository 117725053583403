<template>
  <!-- App shell -->
  <div id="app" class="app">
    <router-view />
    <portal-target name="body" />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { PortalTarget } from "portal-vue";

@Component({
  name: "App",
  components: {
    PortalTarget,
  },
})
export default class App extends Vue {}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: inherit; /* fixes page not scrolling */
}

.badge {
  white-space: nowrap;
}

.table tr > td,
.table tr > th {
  white-space: normal;
}

.table {
  td {
    p {
      font-size: 12px;
    }
  }
}
</style>
