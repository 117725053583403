import { RouteConfig } from "vue-router";
const RouterViewPassthrough = () => import("../views/RouterViewPassthrough.vue");
const ActorsListing = () => import("../views/actors/ActorsListing.vue");
const ActorDetails = () => import("../views/actors/ActorDetails.vue");
const ActorOverview = () => import("../views/actors/ActorOverviewTab.vue");
const ActorReviews = () => import("../views/actors/ActorEventsTab.vue");
const ActorSubjects = () => import("../views/actors/ActorSubjectsTab.vue");
const ActorISPs = () => import("../views/actors/ActorISPsTab.vue");
const ActorIPs = () => import("../views/actors/ActorIPsTab.vue");
const ActorRelatedActors = () => import("../views/actors/ActorRelatedActorsTab.vue");
const ActorNotes = () => import("../views/actors/ActorNotesTab.vue");

const routes: Array<RouteConfig> = [
  {
    path: "profiles/",
    component: RouterViewPassthrough,
    meta: {
      breadcrumb: "Profiles",
      breadcrumbOverrideName: "ActorsListing",
    },
    children: [
      {
        path: "",
        name: "ActorsListing",
        component: ActorsListing,
        meta: {
          breadcrumb: "Profiles",
        },
      },
      {
        path: ":id",
        component: ActorDetails,
        name: "actor>details",
        meta: {
          breadcrumb: function breadcrumb() {
            return this.$store.getters["ActorDetails/actorEmail"](this.$route.params.id);
          },
          breadcrumbOverrideName: "actor>overview",
        },
        redirect: { name: "actor>overview" },
        children: [
          {
            path: "",
            component: RouterViewPassthrough,
            children: [
              {
                path: "",
                name: "actor>overview",
                component: ActorOverview,
                meta: {
                  breadcrumb: "Overview",
                },
              },
              {
                path: "reviews",
                name: "actor>events",
                component: ActorReviews,
                meta: {
                  breadcrumb: "Reviews",
                },
              },
              {
                path: "subjects",
                name: "actor>subjects",
                component: ActorSubjects,
                meta: {
                  breadcrumb: "Businesses",
                },
              },
              {
                path: "ips",
                name: "actor>ips",
                component: ActorIPs,
                meta: {
                  breadcrumb: "IPs",
                },
              },
              {
                path: "isps",
                name: "actor>isps",
                component: ActorISPs,
                meta: {
                  breadcrumb: "ISPs",
                },
              },
              {
                path: "profiles",
                name: "actor>actors",
                component: ActorRelatedActors,
                meta: {
                  breadcrumb: "Profiles",
                },
              },
              {
                path: "notes",
                name: "actor>notes",
                component: ActorNotes,
                meta: {
                  breadcrumb: "Notes",
                },
              },
            ],
          },
        ],
      },
    ],
  },
];

export default routes;
