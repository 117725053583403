import { Module, getModule } from "vuex-module-decorators";
import { IListStoreAssociations, ListingPageStore } from "@pasabi/ui-patterns";
import store from "..";
import { $api } from "../../api/api";
import { Actor } from "../../types";
import { RunsStore } from "./Runs";

@Module({
  name: "ActorsList",
  store,
  dynamic: true,
  namespaced: true,
})
class ActorsList extends ListingPageStore<Actor> {
  get itemsApiPath() {
    return `/actors/${RunsStore.selectedRunId}`;
  }

  _associations: IListStoreAssociations = {
    filters: {
      _apiPath: () => `/actors/${RunsStore.selectedRunId}/filters`,
    },
    model: {
      _apiPath: () => `/actors/${RunsStore.selectedRunId}/model`,
    },
  };

  get api() {
    return $api;
  }
}

export const ActorsListStore = getModule(ActorsList);
