import { RouteConfig } from "vue-router";
const RouterViewPassthrough = () => import("../views/RouterViewPassthrough.vue");
const EventsListing = () => import("../views/events/EventsListing.vue");
const EventDetails = () => import("../views/events/EventDetails.vue");
const EventOverviewTab = () => import("../views/events/EventOverviewTab.vue");
const EventNotesTab = () => import("../views/events/EventNotesTab.vue");
const EventRelatedEventsTab = () => import("../views/events/EventRelatedEventsTab.vue");
const EventExactEventsTab = () => import("../views/events/EventExactEventsTab.vue");
const EventCloseEventsTab = () => import("../views/events/EventCloseEventsTab.vue");

const routes: Array<RouteConfig> = [
  {
    path: "reviews/",
    component: RouterViewPassthrough,
    meta: {
      breadcrumb: "Reviews",
      breadcrumbOverrideName: "EventsListing",
    },
    children: [
      {
        path: "",
        name: "EventsListing",
        component: EventsListing,
        meta: {
          breadcrumb: "Reviews",
        },
      },
      {
        path: ":id",
        component: EventDetails,
        name: "event>details",
        meta: {
          breadcrumb: function breadcrumb() {
            return this.$store.getters["EventDetails/externalId"](this.$route.params.id);
          },
          breadcrumbOverrideName: "event>overview",
        },
        redirect: { name: "event>overview" },
        children: [
          {
            path: "",
            component: RouterViewPassthrough,
            children: [
              {
                path: "",
                name: "event>overview",
                component: EventOverviewTab,
                meta: {
                  breadcrumb: "Overview",
                },
              },
              {
                path: "related_reviews",
                name: "event>relatedEvents",
                component: EventRelatedEventsTab,
                meta: {
                  breadcrumb: "Related Events",
                },
              },
              {
                path: "exact_reviews",
                name: "event>exactEvents",
                component: EventExactEventsTab,
                meta: {
                  breadcrumb: "Exact Events",
                },
              },
              {
                path: "close_reviews",
                name: "event>closeEvents",
                component: EventCloseEventsTab,
                meta: {
                  breadcrumb: "Close Events",
                },
              },
              {
                path: "notes",
                name: "event>notes",
                component: EventNotesTab,
                meta: {
                  breadcrumb: "Notes",
                },
              },
            ],
          },
        ],
      },
    ],
  },
];

export default routes;
