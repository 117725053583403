<template>
  <div>
    <div v-if="isLoading"><ui-preloader /></div>
    <div v-else-if="isLoaded && ip">
      <div class="row row--flow-vertical">
        <div class="col ui-node-overview__heading">
          <dl>
            <dt class="label">
              {{ $t("graph.ips.fields.ipAddress") }}
            </dt>
            <dd class="txt--large txt--bold">
              {{ ip.address }}
            </dd>
          </dl>
        </div>
        <div class="row row--wrap">
          <div :class="`col col--${p.size || 50}`" v-for="p in properties" v-bind:key="p.label">
            <dl>
              <dt>{{ p.label }}</dt>
              <dd>{{ p.value }}</dd>
            </dl>
          </div>
        </div>
        <div class="col" v-if="type == 'overview'">
          <ui-button
            appearance="primary"
            span="100"
            component="router-link"
            target="_blank"
            :to="ipLocation"
          >
            {{ $t("graph.ips.view-details") }}
          </ui-button>
        </div>
      </div>
    </div>
    <div v-else-if="loadingError">
      {{ $t("graph.ips.loadingError") }}
    </div>
  </div>
</template>

<script lang="ts">
import { IGraphNode } from "@pasabi/ui-blossom/types/interfaces/graph";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { UiButton, UiPreloader } from "@pasabi/ui-components";
import { RawLocation } from "vue-router";
import { IPDetailsStore } from "../../store/modules/IPDetails";
import { IP } from "../../types";
import { getCountryNameFromCode } from "../../i18n";

@Component({
  components: {
    UiButton,
    UiPreloader,
  },
})
export default class IPGraphNode extends Vue {
  store = IPDetailsStore;

  @Prop({ required: true }) node!: IGraphNode;
  @Prop({ required: true }) type!: "overlay" | "overview";

  get ipAddress() {
    return this.node.properties.display_id;
  }

  @Watch("ipAddress", { immediate: true })
  ipAddressChanged() {
    if (this.ipAddress) {
      this.loadData();
    }
  }

  loadData() {
    this.store.fetchItemAssociation({ id: this.ipAddress, key: "graph" });
  }

  get properties(): { label: string; value: string | number; size?: number }[] {
    if (this.ip) {
      return [
        {
          label: this.$t("graph.ips.fields.isp").toString(),
          value: this.ip.isp_name,
        },
        {
          label: this.$t("graph.ips.fields.ipScore").toString(),
          value: this.ip.score,
        },
        {
          label: this.$t("graph.ips.fields.vpn").toString(),
          value: this.ip.vpn?.toString() || "-"
        },
        {
          label: this.$t("graph.ips.fields.proxy").toString(),
          value: this.ip.proxy?.toString() || "-",
        },
        {
          label: this.$t("graph.ips.fields.country").toString(),
          value: getCountryNameFromCode(this.ip.country_code),
        },
        {
          label: this.$t("graph.ips.fields.eventsCount").toString(),
          value: this.ip.events_count,
        },
        {
          label: this.$t("graph.ips.fields.subjectsCount").toString(),
          value: this.ip.subjects_count,
        },
        {
          label: this.$t("graph.ips.fields.actorsCount").toString(),
          value: this.ip.actors_count,
        },
      ];
    }
    return [];
  }

  get isLoading() {
    return this.store.isLoadingAssociation({ id: this.ipAddress, key: "graph" });
  }

  get isLoaded() {
    return this.store.isAsssociationLoaded({ id: this.ipAddress, key: "graph" });
  }

  get loadingError() {
    return this.store.associationLoadingError({ id: this.ipAddress, key: "graph" });
  }

  get ip(): IP {
    return this.store.associationData({ id: this.ipAddress, key: "graph" });
  }

  get ipLocation(): RawLocation {
    return {
      name: "ip>details",
      params: {
        ...this.$router.currentRoute.params,
        id: this.ipAddress,
      },
    };
  }
}
</script>
