import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

const LayoutSignedIn = () => import("../views/layouts/LayoutSignedIn.vue");
const Dashboard = () => import("../views/dashboard/Dashboard.vue");
const RouterViewPassthrough = () => import("../views/RouterViewPassthrough.vue");
const LayoutSignedOut = () => import("../views/layouts/LayoutSignedOut.vue");
const Search = () => import("../views/search/Search.vue");

import authRoutes from "./auth";
import subjectsRoutes from "./subjects";
import actorsRoutes from "./actors";
import ipsRoutes from "./ips";
import ispsRoutes from "./isps";
import eventsRoutes from "./events";
import { AuthStore } from "../store/modules/Auth";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/auth",
    component: LayoutSignedOut,
    meta: {
      auth: false,
    },
    children: [...authRoutes],
  },
  {
    path: "/",
    component: LayoutSignedIn,
    meta: {
      auth: true,
    },
    children: [
      {
        path: ":runId?",
        component: RouterViewPassthrough,
        redirect: { name: "Dashboard" },
        children: [
          {
            path: "dashboard",
            name: "Dashboard",
            component: Dashboard,
            meta: {
              breadcrumb: "Dashboard",
            },
          },
          {
            path: "search",
            name: "Search",
            component: Search,
            meta: {
              auth: true,
              breadcrumb: "Search",
            },
          },
          ...subjectsRoutes,
          ...actorsRoutes,
          ...ipsRoutes,
          ...ispsRoutes,
          ...eventsRoutes,
        ],
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, _, next) => {
  if (to.matched.some((route) => route.meta?.auth === true)) {
    if (AuthStore.authenticated()) {
      next();
    } else {
      next({ name: "SignIn", query: { redirect: to.fullPath } });
    }
  } else {
    next();
  }
});

export default router;
