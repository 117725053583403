import "@pasabi/ui-components/dist/css/ui-components.css";
import "@pasabi/ui-charts/dist/css/ui-charts.css";
import "medium-editor/dist/css/medium-editor.css";
import "@pasabi/ui-patterns/dist/css/ui-patterns.css";
import "@pasabi/ui-blossom/dist/css/ui-blossom.css";
import "@/assets/scss/common.scss";

import Vue from "vue";
import UiPatterns from "@pasabi/ui-patterns";
import UiBlossom from "@pasabi/ui-blossom";
import PortalVue from "portal-vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import "./components";
import UiAnalytics from "@pasabi/ui-analytics";
import { AccountStore } from "./store/modules/Account";
import { RunsStore } from "./store/modules/Runs";

Vue.config.productionTip = false;

Vue.use(PortalVue);
Vue.use(UiPatterns);
Vue.use(UiBlossom, { store });
Vue.use(UiAnalytics, {
  id: process.env.VUE_APP_GTM_ID || "GTM-UNDEFINED",
  debug: process.env.NODE_ENV !== "production",
  router: router,
  routerAdditionalEventData: () => {
    return {
      user_id: AccountStore.loggerUser?._id,
      run_id: RunsStore.selectedRunId,
    };
  },
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
